export const testStub = {
  pages: [
    {
      num: 0,
      rotate_angle: 0,
      data: [
        {
          transcription: 'И Г. Л.',
          points: [
            [
              [
                1111,
                759,
              ],
              [
                1130,
                759,
              ],
              [
                1130,
                836,
              ],
              [
                1111,
                836,
              ],
            ],
          ],
          labels: [
            {
              FIO_raw: 0.8709835410118103,
            },
          ],
        },
        {
          transcription: 'N70689',
          points: [
            [
              [
                200,
                398,
              ],
              [
                217,
                398,
              ],
              [
                217,
                470,
              ],
              [
                200,
                470,
              ],
            ],
          ],
          labels: [
            {
              NUM_raw: 0.9671577215194702,
            },
          ],
        },
        {
          transcription: '1385-43',
          points: [
            [
              [
                389,
                834,
              ],
              [
                406,
                834,
              ],
              [
                406,
                934,
              ],
              [
                389,
                934,
              ],
            ],
          ],
          labels: [
            {
              SUM_raw: 0.968056321144104,
            },
          ],
        },
        {
          transcription: '771401001',
          points: [
            [
              [
                783,
                486,
              ],
              [
                800,
                486,
              ],
              [
                800,
                597,
              ],
              [
                783,
                597,
              ],
            ],
          ],
          labels: [
            {
              KPP_raw: 0.978286623954773,
            },
          ],
        },
        {
          transcription: '7714014428',
          points: [
            [
              [
                783,
                167,
              ],
              [
                800,
                167,
              ],
              [
                800,
                291,
              ],
              [
                783,
                291,
              ],
            ],
          ],
          labels: [
            {
              INN_raw: 0.9787538051605225,
            },
          ],
        },
        {
          transcription: '28.07.2022',
          points: [
            [
              [
                199,
                666,
              ],
              [
                216,
                666,
              ],
              [
                216,
                790,
              ],
              [
                199,
                790,
              ],
            ],
          ],
          labels: [
            {
              DATE_raw: 0.9792330861091614,
            },
          ],
        },
        {
          transcription: ' УФК по г. Москве ( ИФНС России № 14 по г. Москве)',
          points: [
            [
              [
                829,
                98,
              ],
              [
                850,
                98,
              ],
              [
                850,
                651,
              ],
              [
                829,
                651,
              ],
            ],
          ],
          labels: [
            {
              RCP_raw: 0.9645050764083862,
            },
          ],
        },
      ],
      shape: [
        1651,
        1275,
      ],
    },
  ],
} as const;
